.valueComponent {
	word-wrap: break-word;
	max-width: 100%;
	display: inline;

	&:empty::after {
		content: '- - -';
		font-weight: normal;
	}
}
