@import '~@deere/fuel-tokens/dist/web/jd-green/tokens.scss';

.labels span {
	font-weight: bold;
}

.labelsSvg {
	font-size: 1rem;
	margin-left: 1px;
}

.copyIcon.extraSpecificity {
	padding: 0;
	width: auto;
	height: auto;
	&.doNotPrint {
		display: none;
	}
}

.copyIcon svg {
	font-size: medium;
}

.copyIcon span {
	margin-right: 100px;
}

.copyIcon button:hover {
	background: none;
}

.iconContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.accordionDetails {
	padding: 2px;
}

.strengthIcon {
	margin: 4px 2px 0 0;
}

.strengthBadge svg {
	height: 18px;
	width: 18px;
	margin-right: 3px;
	margin-bottom: 1px;
}

.detailsIcon {
	max-height: 150px;
	max-width: 150px;
	min-width: 85px;
	min-height: 85px;
}

.detailsIconInline {
	height: 65px;
	width: 65px;
}

.loadingError svg {
	font-size: large;
	color: var(--fuel-color-alert-error-icon);
	margin-top: 3px;
}

.loadingError span {
	font-size: medium;
	color: var(--fuel-color-alert-error-icon);
	position: relative;
}

.accessDeniedError svg {
	font-size: large;
	color: var(--fuel-color-alert-error-icon);
	margin-top: 3px;
}

.accessDeniedError span {
	font-size: medium;
	color: var(--fuel-color-alert-error-icon);
	position: relative;
	margin-top: 6px;
}

.engineHours {
	margin-bottom: -20px;
}

.partsLink {
	word-wrap: break-word;
	max-width: 100%;
	display: flex;
	align-items: center;
}

.partsLink svg {
	margin-left: 4px;
	margin-top: 2px;
	font-size: 16px;
}

.engHrs svg {
	margin-bottom: -3px;
	margin-right: -3px;
	margin-left: 2px;
	margin-top: 10px;
}

.vertical {
	flex-direction: column;
}
