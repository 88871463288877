.pinDetailsContent {
	padding: 0 10px 0 0;
}

.foundMessage {
	display: inline-flex;
	align-items: center;
}

.recentlyViewedRow {
	display: flex;
	flex-direction: row;
	padding: 5px 10px 10px 0;
}

.recentlyViewedRow:last-child {
	padding-bottom: 0;
}

.iconContainer {
	justify-content: center;
	align-items: center;
	flex-shrink: 1;
}

.searchResultsContainer {
	max-width: 1000px;
	position: absolute;
	z-index: 100;
	top: 110%;
}

.recentlyViewedDescription {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.listContainer {
	max-height: 475px;
	overflow: auto;
	margin-bottom: 10px;
}

.foundWrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.foundTypeWrapper {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.searchWrapper {
	position: relative;
}

.pinLoadingRow {
	display: flex;
	width: 100%;
	gap: 10px;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}

.pinLoadingTextRow {
	width: 100%;
	transform: scale(1);
}

.pinLoadingSerialNumber {
	width: 100px;
	height: 15px;
	margin-right: 5px;
	transform: scale(1);
}
