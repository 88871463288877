.iconCell {
	padding: 0;
	cursor: default;
}

.tabWrapper {
	display: inline-flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}

.sortColHeader {
	display: flex;
	gap: 5px;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
}

.sortColHeader:hover .placeholderIcon {
	opacity: 0.4;
}

.placeholderIcon {
	opacity: 0;
	transition: 0.35s;
}
